<template>
  <div id="AnticipatePage">
    <div class="main-section">
      <h1>This is the Anticipation Page!</h1>

      <p>We at Tulip are open to hear from the community as to what they would like to see! Since Tulip is a fully community driven project, we will seek to allow for open feedback from everyone!</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AnticipatePage',
  components: {
  },
  data(){
    return{
    }
  },
  methods:{
  },
}
</script>
<style scoped>
#AnticipatePage{
  width: 100%;
  height: 100%;
  min-height: 100vh;
  color: #fff;
}
.main-section{
  width: 80%;
  margin: 3rem auto;
}
</style>
